import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {catchError, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserOrganisation, UserOrganisationsService} from '../services/user-organisations.service';

export const userOrganisationGuard: CanActivateFn = () => {
  const userOrganisationsService: UserOrganisationsService = inject(UserOrganisationsService);
  const router: Router = inject(Router);

  return userOrganisationsService.getOrganisations().pipe(
    map((userOrganisations: UserOrganisation[]): boolean => {
      if (!userOrganisations || userOrganisations.length === 0) {
        router.navigate(['/start', 'new-organisation']).then();
      }
      return true;
    }),
    catchError((error: Error) => {
      return throwError(() => error);
    })
  );
};
