import {Component, inject, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
  faHouse,
  faCog,
  faDesktop,
  faDisplay,
  faAngleRight,
  faList,
  faSitemap,
  faUserSecret,
  faEarthAmericas
} from '@fortawesome/free-solid-svg-icons';
import {faCopyright, faUser, faBell} from '@fortawesome/free-regular-svg-icons';
import {IconDefinition} from '@fortawesome/free-regular-svg-icons';
import {Router} from '@angular/router';
import {UserOrganisation, UserOrganisationsService} from '../../common/services/user-organisations.service';
import {UserProject, UserProjectsService} from '../../common/services/user-projects.service';
import {MatMenuTrigger} from '@angular/material/menu';
import packageJson from '../../../../package.json';
import {AuthClass} from '../../common/classes/auth.class';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends AuthClass {
  public appVersion: string = packageJson.version;
  // public userSessionObservable: Observable<Session> = this.authService.userSession;
  public activeUserOrganisationObservable: Observable<UserOrganisation> = this.userOrganisationsService.getActiveOrganisation();
  public organisationsListObservable: BehaviorSubject<UserOrganisation[]> = this.userOrganisationsService.userOrganisations;
  public activeUserProjectObservable: Observable<UserProject> = this.userProjectsService.getActiveProject();
  public projectsListObservable: BehaviorSubject<UserProject[]> = this.userProjectsService.userProjects;
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result: BreakpointState) => result.matches),
    shareReplay()
  );
  public faHouse: IconDefinition = faHouse;
  public faDesktop: IconDefinition = faDesktop;
  public faUserSecret: IconDefinition = faUserSecret;
  public faCog: IconDefinition = faCog;
  public faCopyright: IconDefinition = faCopyright;
  public faUser: IconDefinition = faUser;
  public faBell: IconDefinition = faBell;
  public faDisplay: IconDefinition = faDisplay;
  public faAngleRight: IconDefinition = faAngleRight;
  public faList: IconDefinition = faList;
  public faSitemap: IconDefinition = faSitemap;
  public faEarthAmericas: IconDefinition = faEarthAmericas;
  public dateYear: number = new Date().getFullYear();
  @ViewChild('organisationsMenuTrigger') organisationsMenuTrigger: MatMenuTrigger | undefined;
  @ViewChild('projectsMenuTrigger') projectsMenuTrigger: MatMenuTrigger | undefined;

  constructor(
    public router: Router,
    public userOrganisationsService: UserOrganisationsService,
    public userProjectsService: UserProjectsService
  ) {
    super();
  }

  selectOrganisation(event: MouseEvent, organisation: UserOrganisation) {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem('activeOrganisationID', organisation.id);
    sessionStorage.setItem('activeOrganisationName', organisation.name);
    sessionStorage.removeItem('activeProjectID');
    sessionStorage.removeItem('activeProjectName');
    this.userOrganisationsService.userOrganisationsObservable = this.userOrganisationsService.getOrganisations();
    this.activeUserOrganisationObservable = this.userOrganisationsService.getActiveOrganisation();
    this.userProjectsService.userProjectsObservable = this.userProjectsService.getProjects().pipe(
      tap((userProjects: UserProject[]) => {
        if (!userProjects || userProjects.length === 0) {
          this.router.navigate(['/start', 'new-project']).then();
        }
      })
    );
    this.activeUserProjectObservable = this.userProjectsService.getActiveProject();
    this.organisationsMenuTrigger?.closeMenu();
  }

  selectProject(event: MouseEvent, project: UserProject) {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem('activeProjectID', project.id);
    sessionStorage.setItem('activeProjectName', project.name);
    this.userProjectsService.userProjectsObservable = this.userProjectsService.getProjects();
    this.activeUserProjectObservable = this.userProjectsService.getActiveProject();
    this.projectsMenuTrigger?.closeMenu();
  }
}
