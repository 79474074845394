import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, tap, throwError} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
  ListProjectResponse,
  ProjectResponse,
  ProjectsService
} from '@qernal/ngx-chaos-client';

export interface UserProject extends ProjectResponse {
  active: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserProjectsService {
  public userProjects: BehaviorSubject<UserProject[]> = new BehaviorSubject<UserProject[]>([]);
  public userProjectsObservable: Observable<UserProject[]>;

  constructor(
    private projectsService: ProjectsService
  ) {
    this.userProjectsObservable = this.getProjects();
  }

  public getProjects(): Observable<UserProject[]> {
    return this.projectsService.organisationsProjectsList(sessionStorage.getItem('activeOrganisationID') || '').pipe(
      map((listProjectResponse: ListProjectResponse) =>
        listProjectResponse.data.map((projectResponse: ProjectResponse, index: number) => {
          if (!sessionStorage.getItem('activeProjectID') && index === 0) {
            sessionStorage.setItem('activeProjectID', projectResponse.id);
            sessionStorage.setItem('activeProjectName', projectResponse.name);
          }
          return Object.assign(projectResponse, {active: sessionStorage.getItem('activeProjectID') === projectResponse.id}) as UserProject;
        })
      ),
      tap((userProjects: UserProject[]) => this.userProjects.next(userProjects)),
      shareReplay(),
      catchError((error: Error) =>
        throwError(() => error)
      )
    );
  }

  public getActiveProject(): Observable<UserProject> {
    return this.userProjectsObservable.pipe(
      map((userProjects: UserProject[]): UserProject => {
        const activeProject: UserProject | undefined = userProjects.find((userProject: UserProject) => userProject.active);
        return activeProject !== undefined ? activeProject : userProjects[0]
      }),
      shareReplay()
    )
  }

  public removeUserProjects(): void {
    this.userProjectsObservable.pipe(map(() => []));
    sessionStorage.removeItem('activeProjectID');
    sessionStorage.removeItem('activeProjectName');
  }
}
