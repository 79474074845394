import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {catchError, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserProject, UserProjectsService} from '../services/user-projects.service';

export const userProjectsGuard: CanActivateFn = () => {
  const userProjectsService: UserProjectsService = inject(UserProjectsService);
  const router: Router = inject(Router);

  return userProjectsService.getProjects().pipe(
    map((userProjects: UserProject[]): boolean => {
      if (!userProjects || userProjects.length === 0) {
        router.navigate(['/start', 'new-project']).then();
      }
      return true;
    }),
    catchError((error: Error) => {
      return throwError(() => error);
    })
  );
};
